import _ from 'lodash';
import { Utils } from '@firedesktop/react-base';
import { useDispatch, useSelector } from 'react-redux';

import * as Types from '../Config/Types';
import * as Types_Rpa from '../Config/Types_Rpa';

import * as FDFecths from '../Utils/FDFetch';

import { initialState } from '../Redux/initialState';

import _BaseActions from './_BaseActions';
import AuthenticationActions from './Authentication/AuthenticationActions';

export default function BEConfigurationActions() {
    const authentication = useSelector((state: Types.InitialState_TYPE) => state.authentication);
    const configurationBE_Recognition = useSelector((state: Types.InitialState_TYPE) => state.configurationBE_Recognition);

    const { configurationAppState, lockScreenRequest, unLockScreenRequest } = _BaseActions();
    const { actionOn_401, actionOn_403 } = AuthenticationActions();


    const dispatch = useDispatch();
    const runner = new Utils.Fetch.FetchWrapper(undefined, undefined, actionOn_401, actionOn_403);

    const getConfigurationBE_Recognition = (): Types.Configuration_BE_Recognition_State_TYPE => {
        return _.cloneDeep(configurationBE_Recognition);
    };
    // ******************************************************************************************************
    //                  BE Configuration
    // ******************************************************************************************************
    const loadConfigurationBE_Recognition = async (configuration: Types.ConfigurationState_TYPE): Promise<Types.Configuration_BE_Recognition_State_TYPE | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.recognition}AppConfiguration/Base`;
            console.log(`Recognition - BE Configuration Loading, URL: ${url}`);
            dispatch(lockScreenRequest());
            return await runner.get(url, configuration.applicationName, authentication.token).then((configurationBE: Types.Configuration_BE_Recognition_State_TYPE) => {
                dispatch(unLockScreenRequest());
                console.log('Recognition - Be Configuration Loaded', configurationBE);
                return configurationBE;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.warn(`Recognition - Be Configuration NOT Loaded, ${errorMessage}`);
                return undefined;
            });
        }
    };

    const loadConfigurationBE_Runtime = async (configuration: Types.ConfigurationState_TYPE): Promise<Types_Rpa.Configuration_BE_Runtime_State_TYPE | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.runtime}AppConfiguration/Base`;
            console.log(`Runtime - BE Configuration Loading, URL: ${url}`);
            dispatch(lockScreenRequest());
            return await runner.get(url, configuration.applicationName, authentication.token).then((configurationBE: Types_Rpa.Configuration_BE_Runtime_State_TYPE) => {
                dispatch(unLockScreenRequest());
                console.log('Runtime - Be Configuration Loaded', configurationBE);
                return configurationBE;
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.warn(`Runtime - Be Configuration NOT Loaded, ${errorMessage}`);
                return undefined;
            });
        }
    };

    const loadConfigurationBE_Authentication = async (configuration: Types.ConfigurationState_TYPE): Promise<Types.ConfigurationState_TYPE | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.authentication}AppConfiguration/Configuration/${process.env.REACT_APP_TARGET}`;
            console.log(`Authentication - BE Configuration Loading, URL: ${url}`);
            dispatch(lockScreenRequest());
            return await runner.get(url, configuration.applicationName, authentication.token).then((configurationBE: Types.ConfigurationState_TYPE) => {
                dispatch(unLockScreenRequest());
                console.log('Authentication - Be Configuration Loaded', configurationBE);
                if (configurationBE)
                    return { ...configurationBE, loadedBE: true };
                return { loadedBE: true };
            }).catch((errorMessage: any) => {
                dispatch(unLockScreenRequest());
                console.warn(`Authentication - Be Configuration NOT Loaded, ${errorMessage}`);
                return {
                    // True because we do not want to lock fe
                    loadedBE: true
                } as any;
            });
        }
    };

    const loadLanguageBE_Authentication = async (configuration: Types.ConfigurationState_TYPE, language: string): Promise<object | undefined> => {
        if (authentication.token) {
            const url = `${configuration.api?.authentication}AppConfiguration/Language/${language}/${process.env.REACT_APP_TARGET}`;
            console.log(`Authentication - BE Language Loading, URL: ${url}`);
            try {
                dispatch(lockScreenRequest());
                return await runner.get(url, configuration.applicationName, authentication.token).then((labels: object) => {
                    dispatch(unLockScreenRequest());
                    console.log('Authentication - Be Language Loaded', labels);
                    if (labels)
                        return { ...labels, language, loadedBE: true };
                    return { language, loadedBE: true };
                }).catch((errorMessage: any) => {
                    dispatch(unLockScreenRequest());
                    console.warn(`Authentication - Be Language NOT Loaded, ${errorMessage}`);
                    return {
                        // True because we do not want to lock fe
                        language,
                        loadedBE: true
                    } as any;
                });
            }
            catch (err) {
                return {
                    // True because we do not want to lock fe
                    language,
                    loadedBE: true
                } as any;
            }
        }
    };

    const loadConfigurationBE = async (configuration: Types.ConfigurationState_TYPE | undefined, labels: any): Promise<boolean> => {
        if (configuration) {
            // Load configuration from BE and merge with the one in FE
            const newConfiguration = await loadConfigurationBE_Authentication(configuration);
            const mergedConfiguration = newConfiguration ? _.merge(_.cloneDeep(configuration), newConfiguration) : _.cloneDeep(configuration);

            const newLanguage = await loadLanguageBE_Authentication({ ...configuration }, authentication.language);
            const mergedLanguage = newLanguage ? _.merge(_.cloneDeep(labels), newLanguage) : _.cloneDeep(labels);


            // WakeUp Services: loop throw all api and invoke them all together without waiting
            if (mergedConfiguration.api) {
                const keys = Object.keys(mergedConfiguration.api);

                const fetchPromises = keys.map(x => `${(mergedConfiguration.api as any)[x]}`)
                    .filter(x => x.startsWith('http'))
                    .map(x => `${x}Watchdog/Check`)
                    .map(async url => await FDFecths.createAndExecuteFetch('GET', url, undefined, configuration.applicationName, authentication.token));
                // I dont want to await
                Promise.all(fetchPromises);
            }

            let configurationBE_Recognition_index = -1;
            let configurationBE_Recognition;
            let configurationBE_Runtime_index = -1;
            let configurationBE_Runtime;

            const arr: any[] = [];

            if (mergedConfiguration?.recognition) {
                if (mergedConfiguration?.api?.recognition) {
                    configurationBE_Recognition_index = arr.length;
                    arr.push(loadConfigurationBE_Recognition(mergedConfiguration));
                }
                else
                    console.warn('You are trying to load recognition configurationBE but there\'s no recognition api configured');
            }
            if (mergedConfiguration?.runtime) {
                if (mergedConfiguration?.api?.runtime) {
                    configurationBE_Runtime_index = arr.length;
                    arr.push(loadConfigurationBE_Runtime(mergedConfiguration));
                }
                else
                    console.warn('You are trying to load runtime configurationBE but there\'s no runtime api configured');
            }

            const promiseResults = await Promise.all(arr);

            const _initialState = initialState();

            if (configurationBE_Recognition_index > -1) {
                configurationBE_Recognition = promiseResults[configurationBE_Recognition_index] ?? _initialState.configurationBE_Recognition;
            }

            if (configurationBE_Runtime_index > -1) {
                configurationBE_Runtime = promiseResults[configurationBE_Runtime_index] ?? _initialState.configurationBE_Runtime;
            }

            dispatch(configurationAppState(mergedConfiguration, configurationBE_Recognition, configurationBE_Runtime, mergedLanguage));

            return true;
        }

        return false;
    };

    return {
        getConfigurationBE_Recognition,
        loadConfigurationBE
    };
}