import { DocumentEditor_Object } from '../Components/Designers/DocumentEditor';
import { Components } from '@firedesktop/react-base';

import * as Enums from './Enums';

import * as Types_Company from './Types_Company';
import * as Types_Document from './Types_Document';
import * as Types_Person from './Types_Person';
import * as Types_Rec from './Types_Rec';
import * as Types_Rpa from './Types_Rpa';
import * as Types_Signature from './Types_Approval';
import * as Types_TaskList from './Types_Task';
import * as Types_UserManagement from './Types_UserManagement';
import * as Types_Workflow from './Types_Workflow';


// ****************************************************************************************************************
//                                      Common Simple Models
// ****************************************************************************************************************
export type Common_Label_Value_Type = { label: string, value: number }
export type FormControlElement = HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement;

// ****************************************************************************************************************
//                                      Redux
// ****************************************************************************************************************
// Application State Actions Names
export const ACTIONS = {
    SCREEN_LOCK_REQUEST: 'SCREEN_LOCK_REQUEST',
    SCREEN_UNLOCK_REQUEST: 'SCREEN_UNLOCK_REQUEST',
    UPDATE_APP_STATE: 'UPDATE_APP_STATE',
    UPDATE_BULK_APP_STATE: 'UPDATE_BULK_APP_STATE'
};

// Application State Action Types
export type Payload_TYPE = {
    name: string,
    value?: any,
    values?: any
}

export type Action_TYPE = {
    type: string,
    payload: Payload_TYPE
}

export type Action_TEST_TYPE = {
    type: string,
    value: string
}

export type Action_Authentication_TYPE = {
    type: string,
    value: AuthenticationState_TYPE
}

// ***************************************************************************************************
// Authentication State Type
// ***************************************************************************************************
export type Authority_TYPE = {
    authorityTypeDescription: string
    authorityType: number
    description: string
    fields?: any
    id: number
    name: string
    relatedAuthorities?: any
    users?: any
}
export type AccessRight_Type = {
    description: string,
    id: number
    name: string

}
export type Tenant_TYPE = {
    id: string
    name: string
    description: string,
    applications: string
}
export type AuthenticationState_TYPE = {
    accessRights?: AccessRight_Type[]
    authorityList?: Authority_TYPE[],
    tenantList?: Tenant_TYPE[],
    authenticationMode: number,
    fullName?: string,
    language: string,
    name?: string,
    selectedAuthority?: Authority_TYPE,
    selectedTenant?: Tenant_TYPE,
    sessionId: string,
    surname?: string,
    token: string,
    userId: string
    userName: string
}

export type User_Users_Type = {
    items: UserTypes_TYPE[]
    itemsPerPage: number
    page: number
    search: string
    sort: string
    totalItems: number
}

export type UserTypes_TYPE = {
    authorityToUsers?: string
    fields?: string
    id: string
    language: number
    name?: string
    password?: string
    surname?: string
    userName?: string
    userToGroups?: string
}

export type OAuth2LoginResponse_Type = {
    access_rights: AccessRight_Type[]
    access_token: string
    expires_in: number
    language: string
    token_type: string
    username: string
}

// ***********************************************************************************************************
//              Template
// ***********************************************************************************************************
export type Template_Payload_Page_TYPE = {
    base64: string,
    height: number,
    page: number,
    width: number
}
export type Template_Node_Type = {
    id: number,
    height: number,
    offsetX: number,
    offsetY: number,
    page: number,
    width: number
}
export type Template_Details_TYPE = {
    id: number,
    name: string,
    nodes: Template_Node_Type[],
    totalePages: number
}
export type Template_TYPE = {
    id: number,
    name: string
}
export type Templates_Search_TYPE = {
    items: Template_TYPE[],
    page: number,
    itemsPerPage: number,
    totalItems: number,
    sort: string
}
// ***********************************************************************************************************
//              Company
// ***********************************************************************************************************
export type LoadCompanies_Type = (searchText: string, page: number, itemsPerPage: number) => void;
export type Company_TYPE = {
    id: number,
    name: string
}
export type Companies_Search_TYPE = {
    items: Company_TYPE[],
    page: number,
    itemsPerPage: number,
    totalItems: number,
    sort: string
}
// ***********************************************************************************************************
//              Instance Bundle
// ***********************************************************************************************************
export type Rectangle_TYPE = {
    height: number,
    width: number,
    x: number,
    y: number,
}
export type FieldArea_TYPE = {

    fieldValid: boolean,
    /**
     * Value found in the page
     */
    foundValue: string,
    /**
     * If true it has a constant value, show a different Icon in Widget List
     */
    hasConstValue: boolean,
    /**
     * If true there are rules so show a different Icon in Widget List
     */
    hasRule: boolean,
    /**
     * Field name in the template
     */
    name: string,
    /**
     * Page index 0 based, of the relative page
     */
    pageIdx: number,
    /**
     * This is the big rectangle to be drawn around the resultRectangle no backgroung color green and red if not valid
     */
    rectangle?: Rectangle_TYPE,
    /**
     * Space in page where the target has been identified background yellow with white color
     */
    resultRectangle?: Rectangle_TYPE
}
export type AreaViewInfo_TYPE = {
    /**
     * This can be null and it is the field anchor (e.g. the text Data when looking for a data field with Data in KeyString in the template)
     */
    anchorArea: FieldArea_TYPE | undefined | null,
    fieldArea: FieldArea_TYPE
}
export type AnchorCfg_TYPE = {
    allPages: boolean,
    fieldType: number,
    keyString?: string,
    outType: number,
    validatorCfg?: ValidatorCfg_TYPE,
    wholePage: boolean
}
export type ValidatorCfg_TYPE = {
    collateMatchResults: boolean,
    collateResults: boolean,
    expression?: string,
    validatorType: string,
}
export type OnInstance_Field_Process_TYPE = (instanceField_id: number) => void;
export type OnInstance_Field_Amend_Save_TYPE = (instanceField_id: number) => void;
export type OnInstance_Field_Amend_Change_TYPE = (instanceField_id: number, value: string) => void;
export type Instance_Field_Amend_TYPE = {
    _toBeSaved?: boolean,
    id?: number,
    instanceFieldId: number,
    instanceId: number,
    key: string,
    label: string,
    type: string,
    value: string
}

export type LoadDumpDocument = (instanceId: number, reprosses: boolean, merge: boolean, normal: boolean, calcSpaces: boolean) => Promise<string>;
export type LoadDumpDocumentAdvanced = (instanceId: number) => Promise<Types_Rec.Rec_DumpDocument_TYPE | undefined>;
export type LoadDumpField = (instanceId: number, fieldId: number, reprosses: boolean, merge: boolean, normal: boolean, calcSpaces: boolean) => Promise<string>;
export type Dump_TYPE = {
    dump: string
}
export type OnInstanceField_Persist = (instanceField_id: number) => void;
export type OnInstance_Field_Configuration_Property_Change = (name: string, value: any, persist: boolean) => void;
export type Instance_Field_Configuration_TYPE = {
    allPages: boolean,
    anchorCfg?: AnchorCfg_TYPE,
    /**
     * Possible values are in configuration Be coming from BE
     * 
     *  Capture = 0, Constant = 1,, Script = 2
     */
    fieldContentType: Enums.FieldContentTypes_ENUM,
    fieldName: string,
    fieldType: number,
    inFieldConstValue: string,
    invertArithmetic: boolean,
    isArithmetic: boolean,
    /**
     * Set this field as key when looking for template markers in document. 
     */
    isMarker: boolean,
    keyString: string,
    outType: number,
    script: string,
    validatorCfg?: ValidatorCfg_TYPE,
    wholePage: boolean
}
export type On_Instance_Field_Selected_TYPE = (instanceField_id: number) => void;
export type Instance_Field_TYPE = {
    areaViewInfo?: AreaViewInfo_TYPE,
    confidence: number,
    fieldConfiguration: Instance_Field_Configuration_TYPE,
    id: number,
    instanceFieldAmends: Instance_Field_Amend_TYPE[],
    instanceId: number,
    isTable: boolean,
    isValid: boolean,
    key: string,
    value: string,
    type: string
}
export type Instance_Field_Search_TYPE = {
    items: Instance_Field_TYPE[],
    page: number,
    itemsPerPage: number,
    totalItems: number,
    sort: string
}
export type Bundle_Template_TYPE = {
    fieldConfigs: any[],
    locale: string,
    rulesConfigs: any[],
    templateId: number,
    templateName: string
}
export type Instance_Page_Details_TYPE = {
    imageHeight: number,
    imageType: number,
    imageWidth: number,
    pageId: number,
    processedImageHeight?: number,
    processedImageWidth?: number,
    processedImageImageType?: number
}
export type InstanceBundle_Node_Type = {
    id: number,
    height: number,
    offsetX: number,
    offsetY: number,
    page: number,
    width: number
}
export type Instance_Object_ScriptErrorDetail_TYPE = {
    /**
     * Error value in number
     */
    error: number,
    errorMessage: string,
    /**
     * Error Order
     */
    index: number,
    isOk: boolean
}
export type Instance_Object_TYPE = {
    id: number,
    instanceId: number,
    scriptErrorDetailList?: Instance_Object_ScriptErrorDetail_TYPE[]
}
export type Instance_Details_TYPE = {
    createdOn: string,
    externalTemplateId?: number,
    fileName: string,
    id: number,
    instanceFields?: Instance_Field_TYPE[],
    instanceObjs?: Instance_Object_TYPE,
    matchingStatus: Enums.Instance_MatchingStatus_ENUM,
    metadata?: string,
    name: string,
    nodes: InstanceBundle_Node_Type[],
    numPages: number,
    templateId?: number
}

export type ProcessingOptions = {
    disableAutoRotation: boolean
    metadata: string | undefined
    onlyBarcode: boolean
    redoDocumentExtraction: boolean
    redoMatching: boolean
    redoOcr: boolean
    redoRendering: boolean
    redoRules: boolean
    redoVision: boolean
}
export type Instance_ProcessForced_TYPE = (processingOptions: ProcessingOptions, templateId: number) => void;
export type InstanceBundle_Process_TYPE = (instanceBundleId: number, processingOptions: ProcessingOptions) => void;
export type LoadInstanceBundles_Type = (searchText: string, page: number, itemsPerPage: number) => void;
export type InstanceBundle_TYPE = {
    id: number,
    createdOn: string,
    note: string
}
export type InstanceBundle_Search_Type = {
    items: InstanceBundle_TYPE[],
    page: number,
    itemsPerPage: number,
    totalItems: number,
    sort: string
}

export type OnInstanceBundleInstanceSelected_Type = (pageIndex: number, instanceBundleInstance?: InstanceBundleInstance_TYPE) => void;
export type LoadInstanceBundleInstances_Type = (selectedInstanceBundle: InstanceBundle_TYPE, searchText: string, page: number, itemsPerPage: number,
    status?: string, templates?: string) => void;
export type InstanceBundleInstance_TYPE = {
    createdOn: string
    id: number
    fileName?: string
    matchingStatus: Enums.Instance_MatchingStatus_ENUM
    note: string
    numPages: number
    processingStatus: Enums.Instance_ProcessingStatus_ENUM
}
export type InstanceBundleInstance_Search_Type = {
    items: InstanceBundleInstance_TYPE[]
    page: number
    itemsPerPage: number
    totalItems: number
    sort: string
}

export type Instance_Page_Filled_TYPE = {
    instanceDetails: Instance_Details_TYPE
    instancePageDetails: Instance_Page_Details_TYPE
    instanceFields: Instance_Field_Search_TYPE
    instanceTemplates?: Bundle_Template_TYPE[]
}

// ***********************************************************************************************************
//              Configuration
// ***********************************************************************************************************
export type Configuration_Api_State_TYPE = {
    accounting?: string
    authentication?: string
    codeGeneration?: {
        generation?: string
        metaModel?: string
    }
    company?: string
    configuration?: string
    contract?: string
    contractBundle?: string
    doc?: string
    documentAnalyzer?: string
    documentTranslator?: string
    email?: string
    flow?: string
    legge_185_90?: string
    license?: string
    recognition?: string
    registry?: string
    runtime?: string
    scheduler?: {
        configuration?: string
        runtime?: string
    }
    ta?: string
    utils?: string
}

export type Configuration_Hub_State_TYPE = {
    recognition?: string
    runtime?: string
}

export type Configuration_Authentication_State_TYPE = {
    cookies?: boolean
    enableChangePassword?: boolean
    enableForgetPassord?: boolean
    enableFormAuthentication?: boolean
    enableResetPassordWithToken?: boolean
    localStorageName?: string
    powerUser?: boolean
}

export type ConfigurationState_TYPE = {
    /**
     * Acccounting services
     */
    accounting?: {
        activeInvoices?: boolean
        activeInvoicesToSend?: boolean
        passiveInvoices?: boolean
        passiveInvoicesDaContabilizzare?: boolean
    }
    applicationName?: string
    loaded?: boolean
    /**
     * Use to understand if we'va loaded configuration from BE
     */
    loadedBE?: boolean
    locale: string
    alerts?: {
        configuration?: boolean
        runtime?: boolean
    }
    api?: Configuration_Api_State_TYPE
    authentication?: Configuration_Authentication_State_TYPE
    banks?: boolean
    baseConfiguration?: {
        companyType?: boolean
        contanctRole?: boolean
        contract?: {
            contanctType?: boolean
            contractualForms?: boolean
            productType?: boolean
        }
        documentClass?: boolean
        ownershipTypes?: boolean
    }
    codeGenerator?: {
        metaModel?: boolean
    }
    companies?: boolean
    companies_visuraCameraleUpload?: boolean
    contract?: {
        contractDetailsOnPage: 'Normal' | 'Minimal',
        contractDetailsOnPageMinimal_signLabel_show?: boolean
        enabled?: boolean
    }
    document?: {
        commmandType?: boolean
        documentConfiguration?: boolean
        documentManagement?: boolean
        entity?: boolean
        sharePoint?: {
            enabledShare?: boolean
        }
    }
    documentAnalyzer?: {
        configuration: {
            enabled?: boolean
        }
        runtime?: {
            enabled?: boolean
            ethymo?: {
                enabled?: boolean
            }
        }
    }
    documentGenerator?: {
        configuration: {
            enabled?: boolean
        }
        runtime?: {
            enabled?: boolean
        }
    }
    documentTranslator?: {
        enabled?: boolean
        extractor?: {
            enabled?: boolean
        }
    }
    emails?: {
        enabled?: boolean
        address?: boolean
        inbox?: boolean
        outbox?: boolean
    }
    jhContract?: boolean
    hubs?: Configuration_Hub_State_TYPE
    legge_185_90?: {
        enabled: boolean
        intangible?: {
            enabled: boolean
        }
        material?: {
            materialItemEdit?: {
                amountEnabled?: boolean
                customsCodeInString?: boolean
            }
        }
        procedure?: {
            extraElement?: {
                enabled?: boolean
            }
        }
        registry: {
            enabled: boolean
        }
        secretLevel?: boolean
    }
    missingDocument?: boolean
    /**
     * If true, to close a modal you have to click the button, clicking outside will be disabled.
     */
    modalInStaticView?: boolean
    /**
     * If true when we in home page, flowbot does not load used commands
     */
    noMatchPatternGoEmptyPage?: boolean
    operatorCompany?: boolean
    persons?: boolean
    portalConfiguration?: {
        definition?: boolean
        license?: boolean
        oAuth?: boolean
        site?: boolean
        tags?: boolean
        tenant?: boolean
    }
    recognition?: boolean
    runtime?: {
        enabled?: boolean
        engineAddress?: string
    }
    scheduler?: {
        configuration?: boolean
        runtime?: boolean
    }
    ta?: boolean
    task?: {
        configuration?: boolean
        runtime?: boolean
        runtime_filters?: {
            date?: {
                endDate_deltaDays?: number
                startDate_deltaDays?: number
            }
        }
    }
    userManagement?: boolean
    workflow?: {
        configuration?: {
            state?: boolean
        }
        editor?: boolean
        entityViewer?: {
            viewType?: 1 | 2
        }
    }
}

export type Validator_Value_TYPE = {
    id: number
    default: string
    name: string
    types: string[]
}
export type Validators_State_TYPE = {
    valueTypes: Validator_Value_TYPE[]
}
export type ComboTypes_State_TYPE = {
    value: Enums.FieldContentTypes_ENUM
    label: string
}
export type Configuration_BE_Recognition_State_TYPE = {
    fieldContentTypes: ComboTypes_State_TYPE[]
    instance_MatchingStatusTypes: ComboTypes_State_TYPE[]
    validators: Validators_State_TYPE
}

export type ContactStatus_Type = {
    id: number
    description: string
}

export type ContactType_Type = {
    id: number
    description: string
}

// ***********************************************************************************************************
//              State
// ***********************************************************************************************************
export type TemplateEditorState_TYPE = {
    selectedCompany: Company_TYPE
    selectedTemplate: Template_Details_TYPE
    companies: Companies_Search_TYPE
    companyTemplates: Templates_Search_TYPE
    documentEditorObject: DocumentEditor_Object
    templateFields: Array<any>
    templateRules: Array<any>
}

export type BundleEditorState_TYPE = {
    documentEditorObject: DocumentEditor_Object
    instanceBundles: InstanceBundle_Search_Type
    instanceBundleInstances: InstanceBundleInstance_Search_Type
    instanceBundleTemplates: Bundle_Template_TYPE[]
    selectedInstanceBundle: InstanceBundle_TYPE
    selectedInstanceBundleInstance: InstanceBundleInstance_TYPE
    selectedInstanceField_Id?: number
    /**
     * This is where we put all the load instance data coming from BE, 
     * and this is also used to creaate documentEditorObject
     */
    selected_instace_page_filled: Instance_Page_Filled_TYPE
}
export type RpaScriptEditorState_TYPE = {
    autoPlugOnDrop: boolean
    /**
     * Items in palette
     */
    commandsSections: Types_Rpa.RpaCommandsSection_TYPE[]
    /**
     * Translated Object when load from file
     */
    documentEditorObject: DocumentEditor_Object
    /**
     * Selected command
     */
    selectedCommand?: Types_Rpa.RpaScriptCommand_TYPE
    /** 
    * The Open script
    */
    selectedScript?: Types_Rpa.RpaScript_TYPE
}
export type UserHomeState_TYPE = {
    commands: Types_Workflow.WF_Command_TYPE[]
    selectedCommand?: Types_Workflow.WF_Command_TYPE
}

export type InitialState_TYPE = {
    actualTime: string
    authentication: AuthenticationState_TYPE
    bank: Types_Company.Bank_State_TYPE
    bundleEditor: BundleEditorState_TYPE
    company: Types_Company.Company_State_TYPE
    configuration: ConfigurationState_TYPE
    /**
     * Do not use this property directly, just use getConfigurationBE_Recognition from _BaseActions
     */
    configurationBE_Recognition: Configuration_BE_Recognition_State_TYPE
    configurationBE_Runtime: Types_Rpa.Configuration_BE_Runtime_State_TYPE
    document: Types_Document.Document_State_TYPE
    freeSpace: any
    labels: { language: string | undefined | null }
    person: Types_Person.Person_State_TYPE
    rpaScriptEditor: RpaScriptEditorState_TYPE
    signatureConfiguration: Types_Signature.SignatureConfiguration_State_TYPE
    spinnigCounter: number
    taskList: Types_TaskList.TaskListState_TYPE
    templateEditor: TemplateEditorState_TYPE
    test: string
    toaster: Components.Toaster_Types.Toaster_Prop_Type
    userHome: UserHomeState_TYPE
    userManagement: Types_UserManagement.UserManagement_State_TYPE
}

export type State_NAMES =
    | 'actualTime'
    | 'authentication'
    | 'bank'
    | 'bundleEditor'
    | 'company'
    | 'configuration'
    | 'configurationBE_Recognition'
    | 'configurationBE_Runtime'
    | 'document'
    /**
     * This tag should be used to save in the global state generic dynamic stuff not common to the whole application
     */
    | 'freeSpace'
    | 'labels'
    | 'rpaScriptEditor'
    | 'signatureConfiguration'
    | 'spinnigCounter'
    | 'taskList'
    | 'templateEditor'
    | 'test'
    | 'toaster'
    | 'userHome'
    | 'userManagement';