export function createRequestInit(method: 'DELETE' | 'GET' | 'POST' | 'PUT', body: any | undefined, applicationName: string | undefined, token: string | undefined): RequestInit | undefined {
    try {
        const headers = new Headers();

        if (applicationName)
            headers.append('ApplicationName', applicationName);
        if (token)
            headers.append('SessionToken', token);

        headers.append('Cache-Control', 'no-cache, no-store, must-revalidate');
        headers.append('pragma', 'no-cache');
        headers.append('Expires', '0');
        headers.append('X-Content-Type-Options', 'nosniff');
        const requestInit = {
            body,
            cache: 'no-cache',
            credentials: 'omit',
            headers,
            method,
            mode: 'cors'
        } as RequestInit;

        return requestInit;
    }
    catch (err) { console.error(err); }
}

export async function createAndExecuteFetch(method: 'DELETE' | 'GET' | 'POST' | 'PUT', url: string, body: any | undefined, applicationName: string | undefined, token: string | undefined) {
    try {
        const requestInit = createRequestInit(method, body, applicationName, token);
        return await fetch(url, requestInit);
    }
    catch (err) { }
}