import { useState } from 'react';
import { useSelector } from 'react-redux';

import * as Types from '../../Config/Types';

import _BaseActions from '../../actions/_BaseActions';


export type SideBarItem_Type = {
    children?: SideBarItem_Type[],
    href?: string,
    icon?: string,
    id?: string
    text: string
};
export default function useAntdPage() {
    const [collapsed, setCollapsed] = useState(false);

    const authentication = useSelector((state: Types.InitialState_TYPE) => state.authentication);
    const configuration = useSelector((state: Types.InitialState_TYPE) => state.configuration);

    const { getLabel } = _BaseActions();


    const items: SideBarItem_Type[] = [{ href: '#/', icon: 'fa-solid fa-house', text: getLabel('sideBar.homePage') }];

    // *****************************************************************************************
    //                  EGO
    // *****************************************************************************************    
    if ((configuration as any).EGO) {
        const fatturePassive: SideBarItem_Type = {
            children: [
                { href: '#/Accounting/PassiveInvoices', icon: 'fa-solid fa-file-invoice', text: 'Fatture passive' },
                { href: '/Ext/#/Accounting/Passive/URGENTI', icon: 'fa-solid fa-triangle-exclamation', text: 'URGENTI' },
                { href: '/Ext/#/Accounting/Passive/EGOEnergy', icon: 'fa-regular fa-lightbulb', text: 'EGO Energy' },
                { href: '/Ext/#/Accounting/Passive/EGOPower', icon: 'fa-solid fa-power-off', text: 'EGO Power' },
                { href: '/Ext/#/Accounting/Passive/EGOData', icon: 'fa-solid fa-database', text: 'EGO Data' },
                { href: '/Ext/#/Accounting/Passive/EGO', icon: 'fa-solid fa-file-invoice', text: 'EGO' },
                { href: '/Ext/#/Accounting/Passive/Integrazioni', icon: 'fa-solid fa-file-invoice', text: 'Integrazioni' },
                { href: '/Ext/#/Accounting/Passive/NoteCredito', icon: 'fa-solid fa-file-invoice', text: 'Note di credito' },
                { href: '#/Accounting/PassiveDaContabilizzare', icon: 'fa-solid fa-file-invoice', text: 'Da contabilizzare' }
            ],
            icon: 'fa-brands fa-app-store',
            text: 'Fatture Passive'
        };
        items.push(fatturePassive);

        const fattureAttive: SideBarItem_Type = {
            children: [
                { href: '#/Accounting/ActiveInvoices', icon: 'fa-solid fa-file-invoice', text: 'Fatture attive' },
                { href: '/Ext/#/Accounting/Active/EGOEnergy', icon: 'fa-regular fa-lightbulb', text: 'EGO Energy' },
                { href: '/Ext/#/Accounting/Active/EGOPower', icon: 'fa-solid fa-power-off', text: 'EGO Power' },
                { href: '/Ext/#/Accounting/Active/EGOData', icon: 'fa-solid fa-database', text: 'EGO Data' },
                { href: '/Ext/#/Accounting/Active/EGO', icon: 'fa-solid fa-file-invoice', text: 'EGO' },
                { href: '#/Accounting/ActiveInvoicesToSend', icon: 'fa-regular fa-paper-plane', text: 'Da inviare a SDI' },
                { href: '/Ext/#/Accounting/Active/SelfBilling', icon: 'fa-regular fa-paper-plane', text: 'Self billing' }
            ],
            icon: 'fa-brands fa-app-store',
            text: 'Fatture Attive'
        };
        items.push(fattureAttive);

        const gestioneStime: SideBarItem_Type = {
            href: '/Ext/#/GestioneStime',
            icon: 'fa-solid fa-bag-shopping',
            text: 'Gestione stime'
        };
        items.push(gestioneStime);
    }
    else if ((configuration as any).JH) {
        // TODO:...
    }
    else {
        if (configuration.accounting?.activeInvoices || configuration.accounting?.activeInvoicesToSend || configuration.accounting?.passiveInvoices) {
            const item: SideBarItem_Type = {
                children: [],
                icon: 'fa-brands fa-app-store',
                text: getLabel('sideBar.accounting.title')
            };

            if (item.children && configuration.accounting?.activeInvoices)
                item.children.push({ href: '#/Accounting/ActiveInvoices', icon: 'fa-solid fa-file-invoice', text: getLabel('sideBar.accounting.activeInvoices') });

            if (item.children && configuration.accounting?.activeInvoicesToSend)
                item.children.push({ href: '#/Accounting/ActiveInvoicesToSend', icon: 'fa-regular fa-paper-plane', text: getLabel('sideBar.accounting.activeInvoicesToSend') });

            if (item.children && configuration.accounting?.passiveInvoices) {
                item.children.push({ href: '#/Accounting/PassiveInvoices', icon: 'fa-solid fa-file-invoice', text: getLabel('sideBar.accounting.passiveInvoices') });

                if (configuration.accounting?.passiveInvoicesDaContabilizzare)
                    item.children.push({ href: '#/Accounting/PassiveDaContabilizzare', icon: 'fa-solid fa-file-invoice', text: 'Da contabilizzare' });
            }

            items.push(item);
        }
    }

    if (configuration.portalConfiguration) {
        const children: SideBarItem_Type[] = [];

        if (configuration.portalConfiguration.site)
            children.push({ text: getLabel('sideBar.portalConfiguration.site'), href: '#/PortalConfiguration/Site' });

        if (configuration.portalConfiguration.tenant)
            children.push({ text: getLabel('sideBar.portalConfiguration.tenant'), href: '#/PortalConfiguration/Tenant' });

        if (configuration.portalConfiguration.definition) {
            children.push({
                text: getLabel('sideBar.portalConfiguration.definition.title'),
                children: [
                    { text: getLabel('sideBar.portalConfiguration.definition.application'), href: '#/PortalConfiguration/ApplicationDefinition' },
                    { text: getLabel('sideBar.portalConfiguration.definition.module'), href: '#/PortalConfiguration/ModuleDefinition' }]
            });
        }

        if (configuration.portalConfiguration.tags)
            children.push({ text: getLabel('sideBar.baseConfiguration.tag'), href: '#/DataSegregationTags' });

        if (configuration.portalConfiguration.license) {
            children.push({
                text: getLabel('sideBar.portalConfiguration.license.title'),
                children: [
                    { text: getLabel('sideBar.portalConfiguration.license.configuration'), href: '#/PortalConfiguration/License/Configuration' },
                    { text: getLabel('sideBar.portalConfiguration.license.parameter'), href: '#/PortalConfiguration/License/Parameter' }]
            });
        }

        if (configuration.portalConfiguration.oAuth)
            children.push({ text: getLabel('sideBar.portalConfiguration.oAuth'), href: '#/PortalConfiguration/OAuth' });

        if (children.length) {
            items.push({
                children,
                icon: 'fas fa-wrench',
                text: getLabel('sideBar.portalConfiguration.title')
            });
        }
    }

    if (configuration.baseConfiguration) {
        const children: SideBarItem_Type[] = [];

        if (configuration.baseConfiguration.documentClass)
            children.push({ text: getLabel('sideBar.baseConfiguration.documentClass'), href: '#/DocumentClass' });

        if (configuration.baseConfiguration.companyType)
            children.push({ text: getLabel('sideBar.baseConfiguration.companyType'), href: '#/CompanyType' });

        if (configuration.baseConfiguration.contanctRole)
            children.push({ text: getLabel('sideBar.baseConfiguration.contanctRole'), href: '#/ContanctRole' });

        if (configuration.baseConfiguration.ownershipTypes)
            children.push({ text: getLabel('sideBar.baseConfiguration.ownershipTypes'), href: '#/OwnershipType' });

        if (configuration.baseConfiguration.contract) {
            if (configuration.baseConfiguration.contract.contanctType)
                children.push({ text: getLabel('sideBar.baseConfiguration.contractType'), href: '#/ContractType' });

            if (configuration.baseConfiguration.contract.productType)
                children.push({ text: getLabel('sideBar.baseConfiguration.productType'), href: '#/ProductType' });

            if (configuration.baseConfiguration.contract.contractualForms)
                children.push({ text: getLabel('sideBar.baseConfiguration.contractualForms'), href: '#/ContractualForms' });
        }

        if (children.length) {
            items.push({
                children,
                icon: 'fas fa-wrench',
                text: getLabel('sideBar.baseConfiguration.title')
            });
        }
    }

    if (configuration.workflow?.configuration?.state || configuration.workflow?.editor) {
        const element = {
            text: 'Workflow',
            icon: 'fa-solid fa-code-fork',
            children: [] as any
        };

        if (configuration.workflow?.configuration?.state) {
            element.children.push({
                icon: 'fas fa-wrench',
                text: getLabel('sideBar.workflow.configuration.title'),
                children: [{
                    icon: 'fa-solid fa-bullseye',
                    href: '#/Workflow/Configuration/State',
                    text: getLabel('sideBar.workflow.configuration.state')
                }]
            });
        }

        if (configuration.workflow.editor) {
            element.children.push({
                href: '#/Workflow/Editor',
                icon: 'fa-solid fa-screwdriver-wrench',
                text: getLabel('sideBar.workflow.editor')
            });
        }

        items.push(element);
    }

    if (configuration.alerts?.configuration || configuration.alerts?.runtime) {
        const element = {
            icon: 'fa fa-bell',
            text: getLabel('sideBar.alert.title'),
            children: [] as any
        };

        if (configuration.alerts?.configuration) {
            element.children.push({
                icon: 'fa-solid fa-screwdriver-wrench',
                text: getLabel('sideBar.alert.configuration'),
                children: [
                    { text: getLabel('sideBar.alert.alerts'), href: '#/Alerts/Configuration/Alerts' },
                    { text: getLabel('sideBar.alert.alertsConfiguration'), href: '#/Alerts/Configuration/AlertsConfiguration' },
                    { text: getLabel('sideBar.alert.triggers'), href: '#/Alerts/Configuration/AlertTriggers' }
                ]
            });
        }

        if (configuration.alerts?.runtime) {
            element.children.push({
                icon: 'fa-solid fa-motorcycle',
                text: getLabel('sideBar.alert.runtime'),
                children: []
            });
        }

        items.push(element);
    }

    if (configuration.scheduler?.configuration || configuration.scheduler?.runtime) {
        const children: SideBarItem_Type[] = [];

        if (configuration.scheduler?.configuration) {
            children.push({
                icon: 'fa-solid fa-screwdriver-wrench',
                text: 'Configuration',
                href: '#/Scheduler/Configuration'
            });
        }

        if (configuration.scheduler?.runtime) {
            children.push({
                icon: 'fa-solid fa-microchip',
                text: 'Runtime',
                href: '#/Scheduler/Runtime'
            });
        }

        if (children.length) {
            items.push({
                children,
                icon: 'fa-solid fa-microchip',
                text: 'Scheduler'
            });
        }
    }

    if (configuration.document?.documentConfiguration) {
        const item = {
            text: getLabel('sideBar.document.configurationTitle'),
            icon: 'far fa-file-word',
            children: [] as any
        };

        if (configuration.document.commmandType)
            item.children.push({ text: getLabel('sideBar.document.command'), href: '#/Commands' });

        if (configuration.document.entity)
            item.children.push({ text: getLabel('sideBar.document.entity'), href: '#/Entities' });

        item.children.push({ text: getLabel('sideBar.document.documentTemplates'), href: '#/DocumentTemplates' });
        item.children.push({ text: getLabel('sideBar.document.documentBundles'), href: '#/DocumentBundles' });
        item.children.push({ text: getLabel('sideBar.document.approvalConfiguration'), href: '#/ApprovalConfiguration' });
        item.children.push({ text: getLabel('sideBar.document.sharePointConfiguration'), href: '#/SharePointConfiguration' });

        items.push(item);
    }

    if (configuration.documentGenerator?.configuration?.enabled || configuration.documentGenerator?.runtime?.enabled) {
        const element = {
            text: getLabel('sideBar.documentGenerator.title'),
            icon: 'fa-solid fa-recycle',
            children: [] as any
        };

        if (configuration.documentGenerator?.configuration?.enabled) {
            element.children.push({
                text: getLabel('sideBar.documentGenerator.configuration'),
                icon: 'fa-solid fa-file-circle-question',
                children: [
                    {
                        icon: 'fa-solid fa-file-import',
                        text: getLabel('sideBar.documentGenerator.configurationTemplates'),
                        href: '#/DocumentGenerator/Configuration/Templates'
                    }
                ] as any
            });
        }

        if (configuration.documentGenerator?.runtime?.enabled) {
            element.children.push({
                text: getLabel('sideBar.documentGenerator.runtime'),
                icon: 'fa-solid fa-file-circle-check',
                children: [
                    {
                        icon: 'fa-solid fa-file-import',
                        text: getLabel('sideBar.documentGenerator.configurationInstances'),
                        href: '#/DocumentGenerator/Runtime/Instances'
                    }
                ] as any
            });
        }

        items.push(element);
    }

    if (configuration.codeGenerator) {
        const children: SideBarItem_Type[] = [];

        if (configuration.codeGenerator.metaModel)
            children.push({ icon: 'dsd', text: getLabel('sideBar.codeGenerator.metaModel'), href: '#/CodeGenerator/MetaModel' });

        if (children.length) {
            items.push({
                children,
                icon: 'fas fa-wrench',
                text: getLabel('sideBar.codeGenerator.title')
            });
        }
    }

    if (configuration.recognition) {
        items.push({
            icon: 'fas fa-tools',
            text: getLabel('sideBar.templateEditor'),
            href: '#/TemplateEditor'
        });
        items.push({
            icon: 'fas fa-wrench',
            text: getLabel('sideBar.bundleEditor'),
            href: '#/BundleEditor'
        });
    }

    if (configuration.runtime?.enabled) {
        items.push({
            icon: 'far fa-edit',
            text: getLabel('sideBar.scriptEditor'),
            href: '#/RpaScriptEditor'
        });
    }

    if (configuration.jhContract) {
        items.push({
            children: [
                { icon: '', text: 'Dashboard', href: '#/JH/RichiestaApprovazioneContratti/Dashboard' },
                { icon: '', text: 'Home', href: '#/JH/RichiestaApprovazioneContratti/Home' }
            ],
            icon: 'fas fa-wrench',
            text: 'Approvazione Contratti'
        });
    }

    if ((configuration as any).jhAccounting?.esterometro) {
        items.push({
            icon: 'fas fa-archive',
            text: 'Esterometro',
            href: '#/JH/Accounting/Esterometro'
        });
    }
    if ((configuration as any).jhAccounting?.reportIva) {
        items.push({
            icon: 'fa-solid fa-book-bookmark',
            text: 'Report IVA',
            href: '#/JH/Accounting/ReportIva'
        });
    }
    if ((configuration as any).jhAccounting?.registroIva) {
        items.push({
            icon: 'fa-solid fa-book-open',
            text: 'Libro IVA',
            href: '#/JH/RegistroIva'
        });
    }
    if ((configuration as any).jhLibroGiornale) {
        items.push({
            icon: 'fa-solid fa-book-open',
            text: 'Libro giornale',
            href: '#/JH/LibroGiornale'
        });
    }

    if ((configuration as any).jhAccounting?.PassiveInvoices_sentToAIPro || (configuration as any).jhAccounting?.PassiveInvoices_sentToZTravel) {
        const children: SideBarItem_Type[] = [];

        if ((configuration as any).jhAccounting?.PassiveInvoices_sentToAIPro)
            children.push({ text: 'Fatture Passive AIPro', href: '#/JH/Accounting/PassiveInvoices_AIPro', icon: 'fa-solid fa-file-invoice' });
        if ((configuration as any).jhAccounting?.PassiveInvoices_sentToZTravel)
            children.push({ text: 'Fatture Passive ZTravel', href: '#/JH/Accounting/PassiveInvoices_Ztravel', icon: 'fa-solid fa-file-invoice' });

        if (children.length) {
            items.push({
                children,
                icon: 'fas fa-wrench',
                text: 'Note Spese'
            });
        }
    }

    if (configuration.document?.documentManagement) {
        items.push({
            icon: 'fa-solid fa-book',
            text: getLabel('sideBar.document.documents'),
            href: '#/Documents'
        });
    }
    if (configuration.documentTranslator?.enabled) {
        const element = {
            text: getLabel('sideBar.documentTranslator.title'),
            icon: 'fa-solid fa-globe',
            children: [] as any
        };

        element.children.push({
            icon: 'fa-solid fa-globe',
            text: getLabel('sideBar.documentTranslator.main'),
            href: '#/DocumentTranslator/Translator'
        });

        if (configuration.documentTranslator?.extractor?.enabled) {
            element.children.push({
                icon: 'fa-solid fa-tractor',
                text: getLabel('sideBar.documentTranslator.extractor'),
                href: '#/DocumentTranslator/Extractor'
            });
        }

        items.push(element);
    }
    if (configuration.documentAnalyzer?.configuration?.enabled || configuration.documentAnalyzer?.runtime?.enabled) {
        const element = {
            text: getLabel('sideBar.documentAnalyzer.title'),
            icon: 'fa-solid fa-microscope',
            children: [] as any
        };

        if (configuration.documentAnalyzer?.configuration?.enabled) {
            element.children.push({
                text: getLabel('sideBar.documentAnalyzer.configuration'),
                icon: 'fa-solid fa-file-circle-question',
                children: [
                    {
                        icon: 'fa-solid fa-file-import',
                        text: getLabel('sideBar.documentAnalyzer.configurationSettings'),
                        href: '#/DocumentAnalyzer/Configuration/Settings'
                    },
                    {
                        icon: 'fa-solid fa-file-import',
                        text: getLabel('sideBar.documentAnalyzer.configurationTemplates'),
                        href: '#/DocumentAnalyzer/Configuration/Templates'
                    }
                ] as any
            });
        }

        if (configuration.documentAnalyzer?.runtime?.enabled) {
            const runtime = {
                text: getLabel('sideBar.documentAnalyzer.runtime'),
                icon: 'fa-solid fa-file-circle-check',
                children: [
                    {
                        icon: 'fa-solid fa-file-import',
                        text: getLabel('sideBar.documentAnalyzer.runtimeInstances'),
                        href: '#/DocumentAnalyzer/Runtime/Instance'
                    }
                ] as any
            };
            element.children.push(runtime);
            if (configuration.documentAnalyzer?.runtime?.ethymo?.enabled) {
                runtime.children.push({
                    icon: 'fa-solid fa-wand-magic-sparkles',
                    text: 'Ethymo',
                    href: '#/DocumentAnalyzer/Runtime/Ethymo'
                });
            }
        }

        items.push(element);
    }
    /*
        if (configuration.jhContract) {
            items.push({
                children: [
                    { icon: '', text: 'Dashboard', href: '#/JH/RichiestaApprovazioneContratti/Dashboard' },
                    { icon: '', text: 'Home', href: '#/JH/RichiestaApprovazioneContratti/Home' }
                ],
                icon: 'fas fa-wrench',
                text: 'Approvazione Contratti'
            });
        }
    */
    if (configuration.contract?.enabled) {
        items.push({
            icon: 'fas fa-archive',
            text: getLabel('sideBar.contract.contracts'),
            href: '#/Contracts'
        });

        const canIMultiple = authentication.accessRights?.filter(x => x.name === 'Contract_ViewSingMultipleDocumentsPage');
        if (canIMultiple?.length) {
            items.push({
                icon: 'fas fa-archive',
                text: 'Contratti firma multipla',
                href: '#/JH/Contract/MultipleSign'
            });
        }
    }

    if ((configuration as any).jhArchivioContratti) {
        items.push({
            icon: 'fa-solid fa-file-contract',
            text: 'Archivio contratti',
            href: '#/JH/ArchivioContratti'
        });
    }

    if (configuration.missingDocument) {
        items.push({
            icon: 'fas fa-table',
            text: getLabel('sideBar.missingDocuments'),
            href: '#/JH/MissingDocuments'
        });
    }

    if ((configuration as any).jhFMS) {
        items.push({
            children: [
                { icon: 'fa-regular fa-file-word', text: 'Archivio documenti', href: '#/JH/Service/ArchivioDocumenti' },
                { icon: 'fas fa-archive', text: 'Contratti FMS', href: '#/JH/Service/Contracts' }
            ],
            icon: 'fa-regular fa-handshake',
            text: 'Service'
        });
    }

    if (configuration.ta) {
        items.push({
            icon: 'fas fa-table',
            text: getLabel('sideBar.segnalazioniTA'),
            href: '#/JH/TA'
        });
    }

    if ((configuration as any).jhOvertime) {
        items.push({
            children: [
                {
                    icon: 'fas fa-wrench', text: 'Configurazione',
                    children: [
                        { icon: 'fa-solid fa-user-graduate', text: 'TA', href: '#/JH/Overtime/Configuration/TA' },
                        { icon: 'fa-solid fa-user-shield', text: 'RSD', href: '#/JH/Overtime/Configuration/RSD' },
                    ]
                },
                {
                    href: '#/JH/Overtime/OvertimeSheet',
                    icon: 'fa-solid fa-table',
                    text: 'Straordinari'
                }
            ],
            icon: 'fa-solid fa-business-time',
            text: 'Straordinario'
        });
    }

    if ((configuration as any).jhYourIdea) {
        const isAmministratore = authentication.accessRights?.filter(x => x.name === 'jhYourIdea_AMMINISTRATORE')?.length === 1;
        const isValutatore = authentication.accessRights?.filter(x => x.name === 'jhYourIdea_VALUTATORE')?.length === 1;
        const isOsservatore = authentication.accessRights?.filter(x => x.name === 'jhYourIdea_OSSERVATORE')?.length === 1;

        const idea: SideBarItem_Type = {
            children: [
                {
                    href: '#/JH/YourIdea/ManageIdeas',
                    icon: 'fa-regular fa-face-grin-wide',
                    text: 'Le mie idee'
                }
            ],
            icon: 'fa-regular fa-lightbulb',
            text: 'La tua idea'
        };
        if (isAmministratore) {
            idea.children?.push(
                {
                    href: '#/JH/YourIdea/EvaluateIdeas',
                    icon: 'fa-solid fa-list-check',
                    text: 'Valutazione idee'
                });
            idea.children?.push({
                children: [
                    {
                        href: '#/JH/YourIdea/Configuration/AreeImpatto',
                        icon: 'fa-regular fa-square',
                        text: 'Aree Impatto'
                    },
                    {
                        href: '#/JH/YourIdea/Configuration/Filiali',
                        icon: 'fa-regular fa-building',
                        text: 'Filiali'
                    },
                    {
                        href: '#/JH/YourIdea/Configuration/Intervalli',
                        icon: 'fa-solid fa-bars',
                        text: 'Intervalli'
                    },
                    {
                        href: '#/JH/YourIdea/Configuration/Reparti',
                        icon: 'fa-solid fa-bezier-curve',
                        text: 'Reparti'
                    }
                ],
                icon: 'fa-solid fa-screwdriver-wrench',
                text: 'Configurazione'
            });
        }
        else if (isValutatore || isOsservatore) {
            idea.children?.push(
                {
                    href: '#/JH/YourIdea/EvaluateIdeas',
                    icon: 'fa-solid fa-list-check',
                    text: 'Valutazione idee'
                });
        }

        items.push(idea);
    }

    if (configuration.task?.configuration || configuration.task?.runtime) {
        const element = {
            text: 'Task',
            icon: 'far fa-file-word',
            children: [] as any
        };

        if (configuration.task?.configuration) {
            element.children.push({
                icon: 'fa-solid fa-screwdriver-wrench',
                text: getLabel('sideBar.task.configuration'),
                children: [
                    { text: getLabel('sideBar.task.taskDefinition'), href: '#/Task/Configuration/TaskDefinition' },
                ]
            });
        }
        if (configuration.task?.runtime) {
            element.children.push({
                icon: 'fa-solid fa-motorcycle',
                text: getLabel('sideBar.task.runtime'),
                children: [
                    { text: getLabel('sideBar.task.taskList'), href: '#/Task/Runtime/TaskList' },
                    { text: getLabel('sideBar.task.taskTable'), href: '#/Task/Runtime/TaskTable' }
                ]
            });
        }
        items.push(element);
    }

    if (configuration.emails?.enabled) {
        const element = {
            text: 'E-mails',
            icon: 'fa-solid fa-at',
            children: [] as any
        };

        if (configuration.emails?.inbox) {
            element.children.push({
                icon: 'fa-solid fa-inbox',
                text: getLabel('sideBar.email.inbox'),
                href: '#/Email/Inbox'
            });
        }
        if (configuration.emails?.outbox) {
            element.children.push({
                icon: 'fa-solid fa-boxes-packing',
                text: getLabel('sideBar.email.outbox'),
                href: '#/Email/Outbox'
            });
        }
        if (configuration.emails?.address) {
            element.children.push({
                icon: 'fa-regular fa-address-card',
                text: getLabel('sideBar.email.address'),
                href: '#/Email/Address'
            });
        }

        items.push(element);
    }

    if (configuration.banks) {
        items.push({
            icon: 'fa-solid fa-building-columns',
            text: getLabel('sideBar.bank'),
            href: '#/Banks'
        });
    }

    if (configuration.operatorCompany) {
        items.push({
            icon: 'fa-solid fa-building',
            text: getLabel('sideBar.operatorCompany'),
            href: '#/OperatorCompanies'
        });
    }

    if (configuration.companies) {
        items.push({
            icon: 'fas fa-building',
            text: getLabel('sideBar.company'),
            href: '#/Companies'
        });
    }

    if (configuration.persons) {
        items.push({
            icon: 'fa-solid fa-person',
            text: getLabel('sideBar.person'),
            href: '#/Persons'
        });
    }

    if (configuration.userManagement) {
        items.push({
            icon: 'fas fa-users',
            text: getLabel('sideBar.userManagement.title'),
            children: [
                { text: getLabel('sideBar.userManagement.user'), href: '#/UserManUser' },
                { text: getLabel('sideBar.userManagement.group'), href: '#/UserManGroup' },
                { text: getLabel('sideBar.userManagement.authority'), href: '#/UserManAuthority' },
            ]
        });
    }

    // *****************************************************************************************
    //                  185/90
    // *****************************************************************************************
    if (configuration.legge_185_90?.enabled) {
        const subMenuItems: SideBarItem_Type[] = [];

        subMenuItems.push({
            icon: 'fa-solid fa-tower-observation',
            text: getLabel('_185_90.sidebar.operator'),
            href: '#/185/OperationCompany'
        });

        const anagrafiche = {
            icon: 'fa-solid fa-toolbox',
            children: [
                { icon: 'fa-solid fa-shield-halved', text: 'Categorie SeRNI', href: '#/185/SeRNI' }
            ],
            text: 'Anagrafiche'
        };

        if (!configuration.legge_185_90.material?.materialItemEdit?.customsCodeInString)
            anagrafiche.children.push({ icon: 'fa-brands fa-intercom', text: 'Codici doganali', href: '#/185/CustomsCode' });

        anagrafiche.children.push({ icon: 'fa-solid fa-globe', text: 'Geografia', href: '#/185/Geography' });
        anagrafiche.children.push({ icon: 'fa-solid fa-building-shield', text: 'Tipi di Autorizzazione', href: '#/185/AuthorizationType' });
        anagrafiche.children.push({ icon: 'fa-solid fa-person-military-pointing', text: 'Uffici doganali', href: '#/185/CustomsOffice' });
        anagrafiche.children.push({ icon: 'fas fa-wrench', text: 'Unità di misura', href: '#/185/UnitOfMeasure' });

        subMenuItems.push(anagrafiche);
        subMenuItems.push({
            icon: 'fa-solid fa-book',
            text: 'Dossier',
            href: '#/185/Dossier'
        });
        subMenuItems.push({
            icon: 'fas fa-wrench',
            children: [
                { icon: 'fa-solid fa-box-open', text: 'Gestione Materiali', href: '#/185/Material' },
                { icon: 'fa-solid fa-dolly', text: 'Richieste codifica materiali', href: '#/185/Material/Codification' }
            ],
            text: 'Materiali'
        });

        if (configuration.legge_185_90?.registry?.enabled) {
            subMenuItems.push({
                icon: 'fa-solid fa-registered',
                text: 'Registro intra UE',
                href: '#/185/Registry'
            });
        }

        if (configuration.legge_185_90?.intangible?.enabled) {
            subMenuItems.push({
                icon: 'fa-solid fa-registered',
                text: 'Registro trasferimenti intangibili',
                href: '#/185/Intangible/Registry'
            });
        }

        items.push({
            icon: 'fa-solid fa-scale-balanced',
            text: '185/90',
            children: subMenuItems
        });
    }

    // *****************************************************************************************
    //                  Fincantieri Proposal
    // *****************************************************************************************
    if ((configuration as any).fincatieriSIProposal?.enabled) {
        const subMenuItems: SideBarItem_Type[] = [];

        const configurationMenuItems: SideBarItem_Type[] = [{
            icon: 'fa-regular fa-keyboard',
            text: 'Parametri',
            href: '#/FincantieriSIProposal/Configuration/Settings'
        },
        {
            icon: 'fa-solid fa-location-pin',
            text: 'Stati delle richieste (RFQ)',
            href: '#/FincantieriSIProposal/Configuration/RFQState'
        },
        {
            icon: 'fa-solid fa-location-pin',
            text: 'Stati delle Offerte ai clienti',
            href: '#/FincantieriSIProposal/Configuration/CustomerOfferState'
        },
        {
            icon: 'fa-solid fa-location-pin',
            text: 'Stati delle Offerte dei fornitori',
            href: '#/FincantieriSIProposal/Configuration/SupplierOfferState'
        },
        {
            icon: 'fa-solid fa-weight-scale',
            text: 'Unità di misura',
            href: '#/FincantieriSIProposal/Configuration/UoM'
        }];
        subMenuItems.push({
            icon: 'fas fa-wrench',
            text: 'Configurazione',
            children: configurationMenuItems
        });
        subMenuItems.push({
            icon: 'fa-solid fa-tower-observation',
            text: 'Richieste (RFQ)',
            href: '#/FincantieriSIProposal/RequestForQuoteCustomer'
        });

        items.push({
            icon: 'fa-solid fa-scale-balanced',
            text: 'Proposte commerciali',
            children: subMenuItems
        });
    }

    const onBrandClick = () => {
        setCollapsed(!collapsed);
    };

    return {
        collapsed,
        getLabel,
        items,
        onBrandClick
    };
}